import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import cn from 'classnames';
import {
  RedactionAction,
  RedactionStructureType,
  IRedactionListItem,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import RedactionsZeroCase
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/RedactionsList/RedactionsZeroCase';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';
import {
  getIconProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/RedactionsList/helpers';
import {
  checkIsVisible,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/checkIsVisible';
import styles from './redactionsList.scss';

interface IProps {
  collection: IRedactionListItem[],
  redactionTab: RedactionTab,
  deleteShape: (id: string, action: RedactionAction, structureType: RedactionStructureType) => void,
  jumpToArea: (id: string) => void,
  zeroIcon: IconComponentType,
}

const RedactionsList = ({
  collection = [],
  deleteShape,
  redactionTab,
  jumpToArea,
  zeroIcon,
}: IProps) => {
  return (
    <div className={ styles.listWrapper }>
      { collection.length ? (
        <ul className={ styles.redactionsList }>
          { collection.map(({
            redactionId,
            count,
            id,
            label,
            action: shapeAction,
          }) => {
            const {
              action,
              Icon,
              ...iconProps
            } = getIconProps(shapeAction);
            const isVisible = checkIsVisible(shapeAction);

            return (
              <li
                key={ id }
                className={ cn(styles.redactionsItem, { [styles.isDeleted]: shapeAction === RedactionAction.Delete }) }
                onClick={ () => !isVisible && jumpToArea(id) }
              >
                <span className={ styles.itemText }>
                  { label?.length > 200 ? `${ label.slice(0, 200) }...` : label }
                </span>
                { !isVisible && count > 1 && (
                  <span className={ styles.count }>{ count > 99 ? '99+' : count }</span>
                ) }
                <Icon
                  { ...iconProps }
                  onClick={ (e) => {
                    e.stopPropagation();
                    deleteShape(redactionId, action, RedactionStructureType.Redaction);
                  } }
                />
                <Spinner
                  overlay
                  size="small"
                  isVisible={ isVisible }
                  className={ styles.spinner }
                  overlayClassName={ cn({ [styles.spinnerOverlay]: isVisible }) }
                />
              </li>
            );
          }) }
        </ul>
      ) : (
        <RedactionsZeroCase
          icon={ zeroIcon }
          redactionTab={ redactionTab }
        />
      ) }
    </div>
  );
};

export default RedactionsList;
