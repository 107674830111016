import { v4 as uuid } from 'uuid';
import {
  IRedaction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import RedactionToolFactory
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/RedactionToolFactory';
import {
  getState,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/getState';

export const convertRedactionsToShapes = (redactions: IRedaction[], ctx: CanvasRenderingContext2D) => {
  return redactions
    .flatMap((redaction) => redaction.entries.map((entry) => ({
        ...entry,
        label: redaction.label,
        type: redaction.type,
        redactionId: redaction.id,
      })),
    )
    .map(({
      type,
      id,
      redactionId,
      label,
      frames,
      action,
    }) => {
      const shape = RedactionToolFactory.create(type, ctx, frames[0].pageIndex);
      shape.setId(id || uuid());
      shape.setRedactionId(redactionId);
      shape.setLabel(label);
      shape.setState(getState(action));
      action && shape.setAction(action);

      frames.forEach(({
        topLeftX,
        bottomRightX,
        topLeftY,
        bottomRightY,
        pageIndex,
      }) => {
        shape.addControlPoint({
          x: topLeftX,
          y: topLeftY,
          x1: bottomRightX,
          y1: bottomRightY,
          pageIndex,
        });
      });

      return shape;
    });
};
