import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import IconZeroPendingRedactions from '@dealroadshow/uikit/core/components/Icon/IconZeroPendingRedactions';
import IconZeroAppliedRedactions from '@dealroadshow/uikit/core/components/Icon/IconZeroAppliedRedactions';
import IconText from '@dealroadshow/uikit/core/components/Icon/IconText';
import IconUnion from '@dealroadshow/uikit/core/components/Icon/IconUnion';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { Button, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Radio, RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import { Toggle } from '@dealroadshow/uikit/core/components/Toggle';
import {
  IRedactionListItem,
  RedactionAction,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import RedactionsList from './RedactionsList';
import {
  RedactionPlugin,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/useRedactionPlugin';
import Tabs, { RedactionTab } from './Tabs/Tabs';
import Search from './Search';
import {
  groupRedactions,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/groupRedactions';
import {
  getRedactionsTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/getRedactionsTab';
import useConfirmationModal
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/ConfirmationModal/useConfirmationModal';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import styles from './redact.scss';

const tooltipText =
  `You can use the tools above to manually select words or areas to redact, or you can search by keyword to make
   redactions. Note: search by keyword functionality cannot be used on scanned PDFs.`;

interface IProps {
  handleClose: () => void,
  handleRemoveAllRedactions: (activeTab: RedactionTab) => void,
  handleApplyRedactions: () => void,
  redactionPlugin: RedactionPlugin,
  handleSearchValue: (value: string) => Promise<number>,
}

const Redact = ({
  handleClose,
  handleRemoveAllRedactions,
  handleApplyRedactions,
  redactionPlugin,
  handleSearchValue,
}: IProps) => {
  const [redactions, setRedactions] = useState<IRedactionListItem[]>([]);
  const [activeTab, setActiveTab] = useState<RedactionTab>(RedactionTab.Pending);
  const [isResetSearchInput, setIsResetSearchInput] = useState(false);
  const {
    listing: {
      isFetching: isGetFetching,
    },
  } = useDataroomRedactionContext();

  const {
    jumpToArea,
    shapesState,
    deleteShape,
    redactionType,
    setRedactionType,
    isPreviewWithRedaction,
    setPreviewWithRedaction,
    resetSelectedShape,
  } = redactionPlugin;

  const {
    hideConfirmationModal,
    showConfirmationModal,
    isVisible: isConfirmationModalVisible,
    ConfirmationModal,
  } = useConfirmationModal();

  const appliedRedactions = useMemo(() => (
    getRedactionsTab(redactions, RedactionTab.Applied)
  ), [redactions]);

  const pendingRedactions = useMemo(() => (
    getRedactionsTab(redactions, RedactionTab.Pending)
  ), [redactions]);

  const isDisableAppliedButton = (
    !pendingRedactions.length &&
    // TODO Need to delete after dropdown for entries will be implemented
    shapesState.every((shape) => shape.action === RedactionAction.Applied)
  );

  const isDisableRemoveAllButton = activeTab === RedactionTab.Pending
    ? !pendingRedactions.length
    : !appliedRedactions.length;

  const handleOnChange = () => {
    setPreviewWithRedaction((prev: boolean) => !prev);
  };

  const handleOnChangeRadio = (value: RedactionType) => {
    setRedactionType(value);
  };

  const resetSearchInput = () => setIsResetSearchInput((prev) => !prev);

  const onClose = () => {
    handleClose();
    setPreviewWithRedaction(false);
    resetSearchInput();
  };

  const onSearchValue = async (value: string) => {
    resetSelectedShape();
    return handleSearchValue(value);
  };

  const tabProps = {
    deleteShape,
    jumpToArea,
  };

  useEffect(() => {
    setRedactions(groupRedactions(shapesState));
  }, [shapesState]);

  const tabContent = {
    [RedactionTab.Pending]: (
      <RedactionsList
        collection={ pendingRedactions }
        redactionTab={ RedactionTab.Pending }
        zeroIcon={ IconZeroPendingRedactions }
        { ...tabProps }
      />
    ),
    [RedactionTab.Applied]: (
      <RedactionsList
        collection={ appliedRedactions }
        redactionTab={ RedactionTab.Applied }
        zeroIcon={ IconZeroAppliedRedactions }
        { ...tabProps }
      />
    ),
  };

  return (
    <>
      <div className={ styles.redactWrp }>
        <div className={ styles.header }>
          <h4 className={ styles.title }>Redaction</h4>
          <Tooltip
            content={ tooltipText }
            placement="left"
            maxWidth={ 320 }
            wrpClassName={ styles.tooltipWrapper }
            className={ styles.tooltip }
          >
            <IconQuestion
              isSmall
              className={ styles.tooltipIcon }
            />
          </Tooltip>
          <Button
            className={ styles.closeButton }
            variant={ ButtonVariantType.link }
            onClick={ onClose }
            dataTest="closeRedactButton"
          >
            <IconClose />
          </Button>
        </div>
        <div className={ styles.toggleWrapper }>
          <Toggle
            className={ styles.toggleButton }
            checked={ isPreviewWithRedaction }
            onChange={ handleOnChange }
            dataTest="togglePreviewButton"
          />
          <span className={ styles.toggleTitle }>Preview with redaction</span>
        </div>
        { isPreviewWithRedaction && (
          <p className={ styles.previewDescription }>To perform redactions, please exit preview mode.</p>
        ) }
      </div>
      { !isPreviewWithRedaction && (
        <>
          <div className={ styles.toolsContainer }>
            <RadioGroup
              selectedValue={ redactionType }
              onChange={ handleOnChangeRadio }
              className={ styles.radioGroup }
              fieldClassName={ styles.fieldRadioGroup }
              dataTest="redactByRadioGroup"
            >
              <Radio
                label={ <IconText /> }
                checkedClassName={ styles.radioChecked }
                value={ RedactionType.Text }
                dataTest="redactByTextSelectionButton"
                className={ styles.radio }
                radioLabelClassName={ styles.radioLabel }
              />
              <Radio
                label={ <IconUnion /> }
                checkedClassName={ styles.radioChecked }
                value={ RedactionType.Area }
                dataTest="redactByDrawingButton"
                className={ styles.radio }
                radioLabelClassName={ styles.radioLabel }
              />
            </RadioGroup>
            <Search
              handleSearchValue={ onSearchValue }
              isResetSearchInput={ isResetSearchInput }
            />
          </div>
          <>
            { !isGetFetching && (
              <>
                <Tabs
                  activeTab={ activeTab }
                  onTabChange={ setActiveTab }
                  byAppliedCount={ appliedRedactions.length }
                  byPendingCount={ pendingRedactions.length }
                />
                { tabContent[activeTab] }
              </>
            ) }
            <div className={ styles.actionBtnWrapper }>
              <Button
                className={ cn(styles.removeAllButton, {
                  [styles.disabled]: isDisableRemoveAllButton,
                }) }
                variant={ ButtonVariantType.link }
                onClick={ showConfirmationModal }
                disabled={ isDisableRemoveAllButton }
                dataTest={ `removeAll${ activeTab }RedactionsButton` }
              >
                { `Remove All ${ activeTab } Redactions` }
              </Button>
              <Button
                className={ cn(styles.applyAllButton, {
                  [styles.disabled]: isDisableAppliedButton,
                }) }
                variant={ ButtonVariantType.action }
                onClick={ handleApplyRedactions }
                disabled={ isDisableAppliedButton }
                dataTest="applyAllRedactionsButton"
              >
                Apply All Redactions
              </Button>
            </div>
          </>
        </>
      ) }
      <ConfirmationModal
        activeTab={ activeTab }
        isVisible={ isConfirmationModalVisible }
        confirm={ handleRemoveAllRedactions }
        onClose={ hideConfirmationModal }
      />
    </>
  );
};

export default Redact;
