import { Dispatch, SetStateAction, useRef } from 'react';
import {
  IShape,
  IShapesAction,
  RedactionAction, RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

interface IShapesToAction {
  shapes: IShapesAction[],
  action: RedactionAction,
  props: {
    isSearch?: boolean,
    setShapesState: Dispatch<SetStateAction<IShape[]>>,
    redactionIds?: string[],
    oldControlPoints?: IShape<RedactionType.Area>['controlPoints'],
    oldId?: string,
    index?: number,
    idField?: string,
    isUpdate?: boolean,
    shape?: IShape,
    id?: string,
  },
}

export const useShapesToAction = () => {
  const shapesToAction = useRef<IShapesToAction>(null);

  const setShapesToAction = (shapes: IShapesAction[], action: RedactionAction, props: IShapesToAction['props']) => {
    shapesToAction.current = {
      shapes,
      action,
      props,
    };
  };

  const getShapesToAction = () => shapesToAction.current;

  const clearShapesToAction = () => {
    shapesToAction.current = null;
  };

  return {
    setShapesToAction,
    getShapesToAction,
    clearShapesToAction,
  };
};
