import { useEffect } from 'react';
import cn from 'classnames';
import Redact from './Redact';
import {
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import {
  IProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactionMode';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import styles from './redactSidebar.scss';

const RedactSidebar = ({
  isOpen,
  handleClose,
  redactionPlugin,
}: IProps) => {
  const {
    remove: {
      removeAllPendingRedactions,
      removeAllAppliedRedactions,
    },
    apply: {
      applyAllRedactions,
    },
    listing: {
      getRedactions,
    },
    search: {
      searchData,
    },
  } = useDataroomRedactionContext();
  const { updateCollection } = useDataroomExplorerContext();
  const { previewItem } = useDocumentPreviewContext();

  const {
    setRedactionType,
    resetRedactions,
  } = redactionPlugin;

  const getRedactionHandler = () => (
    getRedactions(previewItem.id)
  );

  const onCloseHandler = () => {
    handleClose();
    resetRedactions();
    setRedactionType(RedactionType.Text);
  };

  const handleRemoveAllRedactions = (activeTab: RedactionTab) => {
    const isAppliedTab = activeTab === RedactionTab.Applied;

    const removeRedactions = isAppliedTab
      ? removeAllAppliedRedactions
      : removeAllPendingRedactions;

    removeRedactions(previewItem.id, previewItem.name)
      .then(resetRedactions)
      .then(getRedactionHandler)
      .then(() => isAppliedTab && updateCollection());
  };

  const handleApplyRedactions = () => {
    applyAllRedactions(previewItem.id, previewItem.name)
      .then(resetRedactions)
      .then(getRedactionHandler)
      .then(updateCollection);
  };

  const handleSearch = async (value: string) => searchData(previewItem.id, value);

  useEffect(() => {
    isOpen && getRedactionHandler();
  }, [isOpen]);

  return (
    <div
      className={ cn(styles.sidebar, { [styles.isHidden]: !isOpen }) }
      data-test="documentViewerRedactSidebar"
    >
      <Redact
        redactionPlugin={ redactionPlugin }
        handleClose={ onCloseHandler }
        handleSearchValue={ handleSearch }
        handleApplyRedactions={ handleApplyRedactions }
        handleRemoveAllRedactions={ handleRemoveAllRedactions }
      />
    </div>
  );
};

export default RedactSidebar;
