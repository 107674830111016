import groupBy from 'lodash/groupBy';
import {
  ISerializedShape,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const groupRedactions = (shapesState: ISerializedShape[]) => (
  Object.values(groupBy(shapesState, 'redactionId'))
    .map((group) => ({
      ...group[0],
      count: group.length,
    }))
);
