import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import {
  IRedaction, RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { v4 as uuid } from 'uuid';

export default function useSearch() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [redactions, setRedactions] = useState<IRedaction[]>([]);

  const searchData = async (fileId: number, searchValue: string): Promise<number> => {
    setIsFetching(true);

    const data = {
      dataroomId: dataroom.id,
      fileId,
      search: searchValue,
    };
    try {
      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);
      const { collection } = await redactionRepository.search(data);
      const redactions = collection.length ? [{
        id: uuid(),
        label: searchValue,
        type: RedactionType.Search,
        entries: collection,
      }] : [];

      setRedactions(redactions);
      return collection.length;
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      return 0;
    } finally {
      setIsFetching(false);
    }
  };

  const resetSearchData = () => {
    setRedactions([]);
  };

  return {
    isFetching,
    redactions,
    searchData,
    resetSearchData,
  };
}
