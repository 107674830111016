import { Dispatch, SetStateAction, MutableRefObject } from 'react';
import isEqual from 'lodash/isEqual';
import {
  IOptions,
  IShape,
  RedactionAction,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import RedactionToolFactory
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/RedactionToolFactory';
import {
  isValidControlPoint,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/isValidControlPoint';

type IDrawingShape = IShape<RedactionType.Area>;

export interface IProps {
  activeShape: MutableRefObject<IDrawingShape>,
  setActiveShape: (shape: IDrawingShape | null) => void,
  oldControlPoints: MutableRefObject<IDrawingShape['controlPoints']>,
  pageIndex: number,
  handleInsertShapes: (shapes: IDrawingShape[], options: IOptions) => void,
  setActiveHandleIndex: Dispatch<SetStateAction<number>>,
  handleUpdateShape: (id: string, controlPoints: IDrawingShape['controlPoints']) => void,
  canvasRef: MutableRefObject<HTMLCanvasElement>,
  activeHandleIndex: number,
  existingShape?: IDrawingShape,
}

export interface IWithCoordinates extends IProps {
  x: number,
  y: number,
  movementX?: number,
  movementY?: number,
}

export const areaMap = {
  noActiveShape: (props: IWithCoordinates): boolean => {
    const {
      x,
      y,
      setActiveShape,
      oldControlPoints,
      existingShape,
    } = props;

    if (existingShape) {
      if (existingShape.action !== RedactionAction.Add) return true;

      existingShape.setIsActive(true);
      setActiveShape(existingShape);
      // save controlPoints if resizing/dragging will be with error
      oldControlPoints.current = existingShape.controlPoints;
      return false;
    }

    areaMap.createShape({
      x,
      y,
      ...props,
    });
    return true;
  },
  createShape: (
    {
      x,
      y,
      setActiveShape,
      pageIndex,
      canvasRef,
    }: IWithCoordinates,
  ) => {
    const shape = RedactionToolFactory.create(
      RedactionType.Area,
      canvasRef.current.getContext('2d'),
      pageIndex,
    ) as IDrawingShape;

    shape.addControlPoint({
      x,
      y,
      pageIndex,
    });
    setActiveShape(shape);
  },
  finishedShape: (
    {
      x,
      y,
      activeShape,
      setActiveShape,
      oldControlPoints,
      pageIndex,
      setActiveHandleIndex,
    }: IWithCoordinates,
  ): void => {
    const index = activeShape.current.getHandleIndex(x, y);
    setActiveHandleIndex(index);

    if (index !== null) {
      // save controlPoints if resizing/dragging will be with error
      oldControlPoints.current = activeShape.current.controlPoints.map((point) => ({ ...point }));
    }

    if (activeShape.current.isShapeArea(x, y, pageIndex)) {
      activeShape.current.setIsDragging(true);
      return;
    }

    setActiveShape(null);
  },
  notFinishedShape: (
    {
      x,
      y,
      activeShape,
      handleInsertShapes,
      setActiveShape,
    }: IWithCoordinates,
  ): boolean => {
    if (!activeShape.current) return false;

    activeShape.current.addControlPoint({
      x1: x,
      y1: y,
    });

    if (!isValidControlPoint(activeShape.current.controlPoints[0])) {
      setActiveShape(null);
      return true;
    }

    if (!activeShape.current.isDragging && activeShape.current.isFinished()) {
      handleInsertShapes([activeShape.current], { isAreaPending: true });
      setActiveShape(null);
    }

    return false;
  },
  actionOnShape: (
    {
      x,
      y,
      movementX,
      movementY,
      activeShape,
      activeHandleIndex,
    }: IWithCoordinates,
  ): boolean => {
    if (!activeShape.current) return true;

    if (activeHandleIndex !== null) {
      activeShape.current.resize(activeHandleIndex, x, y);
      return true;
    }

    if (activeShape.current.isDragging) {
      activeShape.current.move(movementX, movementY);
      return true;
    }

    return false;
  },
  drawNewShape: (
    {
      x,
      y,
      canvasRef,
      activeShape,
    }: IWithCoordinates,
  ) => {
    canvasRef.current.style.cursor = 'crosshair';
    activeShape.current.draw({
      x1: x,
      y1: y,
    });
  },
  finishAction: ({
    activeShape,
    oldControlPoints,
    handleUpdateShape,
    setActiveShape,
    activeHandleIndex,
  }: IProps) => {
    if (activeShape.current) {
      const shouldUpdate = (
        !isEqual(oldControlPoints.current[0], activeShape.current?.controlPoints[0]) &&
        (activeShape.current.isDragging || activeHandleIndex !== null)
      );

      shouldUpdate && handleUpdateShape(activeShape.current.redactionId, oldControlPoints.current);

      activeShape.current.setIsDragging(false);
      activeShape.current.setIsActive(false);
      activeShape.current.isFinished() && setActiveShape(null);
    }
  },
};
